

import IconCheck from '~/components/icons/IconCheck.vue'

export default {
  name: `CheckOrderConfirmed`,
  props: {
    myCheck: {
      type: Object,
      default: undefined
    }
  },
  components: {
    IconCheck
  },
  data() {
    return { 
      toggleDialog: this.shouldShow()
    }
  },
  methods: {
    shouldShow() {
      if (this.$route.query.order !== `success`) {
        return false;
      } else if (!this.myCheck) {
        return false;
      } else if (![`swiss_criminal_record`, `internet_profile`, `medical_test`].includes(this.myCheck?.type?.check_code)) {
        return false;
      }

      this.$router.replace({ query: { ...this.$route.query, order: undefined } });
      return true;
    }
  }
}
